var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-product-bag"},[_c('router-link',{attrs:{"to":{
      name: 'ProductDetail',
      params: {
        product: _vm.product.url.replace('product-', ''),
        talla: _vm.product.referencia.talla.id,
      },
      query: { color: _vm.product.referencia.color_id },
    }}},[_c('img',{staticStyle:{"aspect-ratio":"8/9"},attrs:{"src":_vm.product.referencia.imagenes[0],"alt":""}}),(_vm.product.producto_aplica_estrategia == 1)?_c('img',{staticClass:"img-product-estrategia-bag-movil",attrs:{"src":require("@/assets/icons/gift.svg"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"content-product-bag"},[_c('div',{staticClass:"text-product-bag"},[_c('router-link',{staticStyle:{"display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical","overflow":"hidden"},attrs:{"to":{
          name: 'ProductDetail',
          params: {
            product: _vm.product.url.replace('product-', ''),
            talla: _vm.product.referencia.talla.id,
          },
          query: { color: _vm.product.referencia.color_id },
        }}},[_c('p',{style:(_vm.product.status == 0 ? 'opacity: .4;' : '')},[_vm._v(" "+_vm._s(_vm.product.producto_titulo .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" ")])]),_c('p',{staticClass:"data-text-product-bag",style:(_vm.product.status == 0 ? 'opacity: .4' : '')},[_vm._v(" SKU: "+_vm._s(_vm.product.producto_sku)+" "),_c('br'),_vm._v(" Talla: "+_vm._s(_vm.product.referencia.talla.name)+" "),_c('span',{staticClass:"mb-0 d-none d-md-block px-2"},[_vm._v("/")]),_c('br',{staticClass:"d-block d-md-none"}),_vm._v(" Color: "+_vm._s(_vm.product.referencia.color_nombre .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" ")]),(_vm.product.tags.length > 0)?_c('div',{staticClass:"d-flex flex-wrap mb-2"},_vm._l((_vm.product.tags),function(tag,index){return _c('span',{key:index,staticClass:"d-flex pt-1 me-2"},[_c('label',{staticStyle:{"font-size":"14px","padding":".1rem .4rem","font-weight":"bold"},style:(`background-color: ${tag.color_fondo}; color: ${tag.color_texto}`)},[_vm._v(_vm._s(tag.tag))])])}),0):_vm._e(),(_vm.product.dia_siniva == 1)?_c('div',{staticClass:"price-text-product-bag text-center d-flex d-md-none mb-3"},[(_vm.product.status == 1 && _vm.dataCart.sin_iva == 1)?_c('del',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.product.precio)))]):_vm._e(),(_vm.product.status == 1)?_c('p',{staticClass:"text-end mt-2 ms-3",staticStyle:{"line-height":".8","font-size":"1.1rem"}},[_c('b',[_vm._v("$"+_vm._s(_vm.dataCart.sin_iva == 1 ? new Intl.NumberFormat('de-DE').format( _vm.product.producto_precio_siniva, ) : new Intl.NumberFormat('de-DE').format(_vm.product.precio)))]),_c('br'),(_vm.dataCart.sin_iva == 1)?_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v("*Precio sin IVA")]):_vm._e()]):_vm._e(),(_vm.product.status == 0)?_c('router-link',{staticStyle:{"color":"#0d6efd"},attrs:{"to":{
            name: 'TiendaCategory',
            params: { category: _vm.product.categoria.toLowerCase() },
          }}},[_vm._v(" Ver sugerencias ")]):_vm._e()],1):(_vm.product.status == 1)?_c('div',{staticClass:"d-flex d-md-none mb-1"},[(_vm.product.status == 1 && _vm.product.precio_antes > 0)?_c('del',{staticClass:"pe-2",staticStyle:{"color":"#949494"}},[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.product.precio_antes * _vm.product.count_products, )))]):_vm._e(),_c('p',{staticClass:"price-movil-bag mt-0 pb-0"},[_vm._v(" "+_vm._s(_vm.product.precio == 0 ? 'Gratis' : '$' + new Intl.NumberFormat('de-DE').format( _vm.product.precio * _vm.product.count_products, ))+" ")])]):_vm._e(),(_vm.product.producto_aplica_estrategia == 0 && _vm.product.status == 1)?_c('div',{staticClass:"d-flex flex-wrap pb-2 pt-3"},[_c('div',{staticClass:"box-count-product"},[_c('span',{staticClass:"less",on:{"click":function($event){return _vm.deleteOneProduct()}}},[_vm._v("-")]),_c('span',{staticClass:"box-count"},[_vm._v(_vm._s(_vm.product.count_products))]),_c('span',{staticClass:"more",on:{"click":function($event){return _vm.addProductOneProductToCart()}}},[_vm._v("+")])])]):_vm._e(),_c('div',{class:_vm.product.status == 1 && _vm.product.referencia.tallas.length > 1
            ? 'mb-4'
            : ''},[(_vm.product.status == 1 && _vm.product.referencia.tallas.length > 1)?_c('span',{staticClass:"btn-edit-product-bag",on:{"click":function($event){_vm.showEditSize = true}}},[_vm._v("Editar")]):_vm._e()]),(_vm.product.status == 0)?_c('span',{staticClass:"out-of-stock"},[_vm._v("Este producto está agotado")]):_vm._e(),(_vm.product.status == 0)?_c('router-link',{staticClass:"d-flex d-md-none my-1",staticStyle:{"color":"#0d6efd"},attrs:{"to":{
          name: 'TiendaCategory',
          params: { category: _vm.product.categoria.toLowerCase() },
        }}},[_vm._v(" Ver sugerencias ")]):_vm._e()],1),(_vm.product.status == 1)?_c('div',{staticClass:"divider position-relative d-none d-md-flex"},[(_vm.product.producto_aplica_estrategia == 1)?_c('img',{staticClass:"img-product-estrategia-bag",attrs:{"title":"Producto de promoción","src":require("@/assets/icons/gift.svg"),"alt":""}}):_vm._e()]):_vm._e()]),(_vm.product.dia_siniva == 1)?_c('div',{staticClass:"price-text-product-bag text-center d-none d-md-block"},[(_vm.product.status == 1 && _vm.dataCart.sin_iva == 1)?_c('del',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.product.precio)))]):_vm._e(),(_vm.product.status == 1)?_c('p',{staticClass:"text-end mt-2",staticStyle:{"line-height":".8","font-size":"1.1rem"}},[_c('b',[_vm._v("$"+_vm._s(_vm.dataCart.sin_iva == 1 ? new Intl.NumberFormat('de-DE').format( _vm.product.producto_precio_siniva, ) : new Intl.NumberFormat('de-DE').format(_vm.product.precio)))]),_c('br'),(_vm.dataCart.sin_iva == 1)?_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v("*Precio sin IVA")]):_vm._e()]):_vm._e(),(_vm.product.status == 0)?_c('router-link',{staticStyle:{"color":"#0d6efd"},attrs:{"to":{
        name: 'TiendaCategory',
        params: { category: _vm.product.categoria.toLowerCase() },
      }}},[_vm._v(" Ver sugerencias ")]):_vm._e()],1):_c('div',{staticClass:"price-text-product-bag text-center d-none d-md-block"},[(_vm.product.status == 1)?_c('p',[_vm._v(" $"+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.product.precio * _vm.product.count_products, ))+" ")]):_vm._e(),(_vm.product.status == 1 && _vm.product.precio_antes > 0)?_c('del',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.product.precio_antes * _vm.product.count_products, )))]):_vm._e(),(_vm.product.status == 0)?_c('router-link',{staticStyle:{"color":"#0d6efd"},attrs:{"to":{
        name: 'TiendaCategory',
        params: { category: _vm.product.categoria.toLowerCase() },
      }}},[_vm._v(" Ver sugerencias ")]):_vm._e()],1),_c('div',{staticClass:"delete-product-bag"},[(!_vm.loading)?_c('img',{attrs:{"src":require("@/assets/icons/delete-gray-icon.svg"),"alt":"boton cerrar"},on:{"click":function($event){return _vm.deleteProduct()}}}):_c('div',{staticClass:"loader loader-delete"})]),(_vm.showEditSize)?_c('div',{staticClass:"box-edit-size-product"},[_c('div',{staticClass:"container-edit-size-product"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('p',{staticClass:"mb-2 w-100 fs-6"},[_vm._v("Elige una talla:")]),_vm._l((_vm.product.referencia.tallas),function(sizeProduct,index){return _c('div',{key:index,staticClass:"d-flex pe-2"},[_c('span',{staticClass:"sizes-product",class:sizeProduct.id == _vm.changeSize ? 'size-selected' : '',style:(_vm.missingSize
                ? `animation-name: missingSize; animation-duration: .5s;`
                : ''),attrs:{"title":sizeProduct.stock == 0
                ? 'Talla agotada'
                : 'Talla: ' + sizeProduct.name},on:{"click":function($event){_vm.changeSize = sizeProduct.id}}},[_vm._v(" "+_vm._s(sizeProduct.name)+" ")])])})],2),_c('div',{staticClass:"btn-save-edit-product-bag"},[_c('button',{staticClass:"btn-primary w-100 p-0",on:{"click":function($event){return _vm.editSize()}}},[(!_vm.loadingEdit)?_c('div',[_vm._v("Guardar")]):_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"m-auto loader loader-delete"})])])])]),_c('img',{attrs:{"src":require("@/assets/icons/delete-gray-icon.svg"),"alt":"boton cerrar"},on:{"click":function($event){_vm.showEditSize = false}}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }