<template>
  <div>
    <b-modal
      :id="'modal-product-detail-' + product.producto_sku"
      class="m-5"
      size="lg"
      centered
      hide-header
      hide-footer
      @show="showImgCarousel"
    >
      <div class="d-flex flex-wrap p-0">
        <div class="col-12 col-md-6 position-relative p-3 pt-4 p-md-0">
          <carousel
            v-if="showImgsCarousel"
            :key="innerWidth"
            class="col-12 mx-0 carousel-modal-product-detail"
            :dots="false"
            :lazy-load="true"
            :loop="true"
            :nav="false"
            :responsive="{ 0: { items: 1, nav: false, stagePadding: 0 } }"
          >
            <template slot="prev"
              ><span class="prev-button">
                <img src="@/assets/icons/left-carousel.svg" /> </span
            ></template>
            <template slot="next"
              ><span class="next-button">
                <img src="@/assets/icons/right-carousel.svg" /> </span
            ></template>
            <div
              v-for="(img, index) in product.imagenes
                ? product.imagenes
                : product.referencia.imagenes"
              :key="index"
              class="imgs-modal-carousel-product-detail"
              :to="{}"
            >
              <img :src="img" :alt="product.producto_titulo" />
            </div>
          </carousel>
          <div style="position: absolute; top: 0; left: 0; z-index: 1">
            <span
              v-for="(tag, index) in product.tags"
              :key="index"
              :style="
                `z-index: 2; background-color: ${tag.color_fondo}; color: ${tag.color_texto}`
              "
              class="store-product-tags"
              >{{ tag.tag }}</span
            >
          </div>
        </div>
        <div class="col-12 col-md-6 modal-data-product-detail">
          <div
            class="container-modal-data-product-detail pt-0 pt-md-3 text-start mb-auto"
          >
            <h2 class="title-product-detail-modal">
              {{ product.producto_titulo }}
            </h2>
            <span class="sku-product-detail-modal"
              >SKU: {{ product.producto_sku }}</span
            >
            <div
              v-if="product.dia_siniva == 1"
              class="price-product-detail-modal"
            >
              <del
                >${{
                  new Intl.NumberFormat('de-DE').format(
                    product.producto_precio
                      ? product.producto_precio
                      : product.precio,
                  )
                }}</del
              >
              <p class="ms-3">
                <b
                  >${{
                    new Intl.NumberFormat('de-DE').format(
                      product.producto_precio_siniva,
                    )
                  }}</b
                >
              </p>
            </div>
            <div v-else class="price-product-detail-modal">
              <p>
                <b
                  >${{
                    new Intl.NumberFormat('de-DE').format(
                      product.producto_precio
                        ? product.producto_precio
                        : product.precio,
                    )
                  }}</b
                >
              </p>
              <del
                v-if="
                  (product.precio_antes && product.precio_antes > 0) ||
                    (product.producto_precio_antes &&
                      product.producto_precio_antes > 0)
                "
                >${{
                  new Intl.NumberFormat('de-DE').format(
                    product.precio
                      ? product.precio
                      : product.producto_precio_antes,
                  )
                }}</del
              >
            </div>
            <div class="box-sizes-product-detail">
              <span
                v-html="
                  (product.tallas &&
                    product.tallas.length == 1 &&
                    product.tallas[0].id == 'UN') ||
                  (product.referencia &&
                    product.referencia.tallas.length == 1 &&
                    product.referencia.tallas[0].id == 'UN')
                    ? 'Talla: <b>' +
                      (product.tallas
                        ? product.tallas[0].name
                        : product.referencia.tallas[0].name) +
                      '</b>'
                    : 'Selecciona tu talla'
                "
              ></span>
              <div
                v-if="
                  !(
                    (product.tallas &&
                      product.tallas.length == 1 &&
                      product.tallas[0].id == 'UN') ||
                    (product.referencia &&
                      product.referencia.tallas.length == 1 &&
                      product.referencia.tallas[0].id == 'UN')
                  )
                "
                class="select-size-product"
              >
                <div class="d-flex flex-wrap">
                  <div
                    v-for="(size, index) in product.tallas
                      ? product.tallas
                      : product.referencia.tallas"
                    :key="index"
                    class="d-flex pe-3"
                  >
                    <span
                      :style="
                        missingSize
                          ? `animation-name: missingSize; animation-duration: .5s;`
                          : ''
                      "
                      class="sizes-product"
                      :class="selectedSize == size.id ? 'size-selected' : ''"
                      @click="
                        msgError = false
                        selectedSize = size.id
                      "
                      >{{ size.name }}</span
                    >
                  </div>
                </div>
              </div>
              <span v-if="msgError" class="msgError">Selecciona una talla</span>
            </div>
          </div>
          <div class="w-100 mt-auto px-3 pt-4">
            <button
              type=""
              class="btn-primary btn-add-cart-product-detail"
              @click="agregarProducto()"
            >
              <div v-if="!loading">
                <img src="@/assets/icons/shopping-bag.svg" alt="" /> Agregar a
                la bolsa
              </div>
              <div v-else class="d-flex justify-content-center">
                Cargando...
                <div class="loader" />
              </div>
            </button>
          </div>
          <div class="w-100 px-3 mt-auto pt-2 pb-1">
            <button
              style="color: black"
              class="w-100 btn-summary-data-cupo"
              @click="
                $router.push({
                  path: product.url,
                  query: { color: product.color },
                  params: dataga4,
                })
              "
            >
              Ver detalles del producto
            </button>
          </div>
        </div>
        <img
          class="btn-close-modal-product-detail"
          src="@/assets/icons/delete-gray-icon.svg"
          alt="boton de cerrar"
          @click="
            $bvModal.hide('modal-product-detail-' + product.producto_sku)
            showModalAddProduct = false
          "
        />
      </div>
    </b-modal>
  </div>
</template>
<script>
import carousel from 'v-owl-carousel'
// import ButtonFav from '@/components/Templates/ButtonFav'

import { mapState, mapActions } from 'vuex'
export default {
  components: { carousel /*ButtonFav*/ },
  props: {
    product: {
      type: Object,
      required: false,
      default: () => {},
    },
    dataga4: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      showImgsCarousel: false,
      selectedSize: '',
      missingSize: false,
      msgError: false,
      loading: false,
      showModalAddToCart: false,
    }
  },
  computed: {
    ...mapState('cart', ['msgErrorAddProduct']),
    ...mapState(['innerWidth']),
  },
  watch: {
    innerWidth() {
      this.validateDotsCarousel()
    },
  },
  mounted() {
    if (
      this.product.tallas &&
      this.product.tallas.length == 1 &&
      this.product.tallas[0].id == 'UN'
    ) {
      this.selectedSize = this.product.tallas[0].id
    } else if (
      this.product.referencia &&
      this.product.referencia.tallas.length == 1 &&
      this.product.referencia.tallas[0].id == 'UN'
    ) {
      this.selectedSize = this.product.referencia.tallas[0].id
    }
  },
  methods: {
    ...mapActions('cart', ['addToCart']),
    async agregarProducto() {
      if (this.selectedSize) {
        let elThis = this
        this.loading = true
        let dataProductAddToCart = {
          size: this.selectedSize,
          color: this.product.color
            ? this.product.color
            : this.product.referencia.color_id,
          sku: this.product.producto_sku,
        }
        await this.addToCart(dataProductAddToCart).then(() => {
          if (this.msgErrorAddProduct == '') {
            // DK-AddToCart
            // window.dataLayer.push({
            //   event: 'DK-AddToCart',
            //   item_list_name: this.$route.name,
            //   currency: 'COP',
            //   items: {
            //     discount: this.product.precio_antes,
            //     item_brand: this.product.marca,
            //     item_category: this.product.categoria,
            //     item_category2: this.product.subcategoria,
            //     item_category3: this.product.genero,
            //     item_id: this.product.producto_sku,
            //     item_name: this.product.titulo,
            //     price: this.product.precio,
            //     color: this.product.color
            //       ? this.product.color
            //       : this.product.referencia.color_id,
            //     talla_id: this.selectedSize,
            //   },
            //   value: this.product.precio,
            // })
            // this.$gtag.event('add_to_cart', {
            //   currency: 'COP',
            //   items: {
            //     affiliation: 'Derek',
            //     coupon: '',
            //     discount: this.product.precio_antes,
            //     item_brand: this.product.marca,
            //     item_category: this.product.categoria,
            //     item_category2: this.product.subcategoria,
            //     item_category3: this.product.genero,
            //     item_id: this.product.producto_sku,
            //     item_name: this.product.titulo,
            //     item_variant: this.product.referencias,
            //     price: this.product.precio,
            //   },
            //   value: this.product.precio,
            // })
            elThis.$bvModal.hide(
              'modal-product-detail-' + elThis.product.producto_sku,
            )
            if (this.$route.name != 'MyBag') {
              elThis.$bvModal.show('modal-add-to-cart')
            }
          }
          this.loading = false
        })
      } else {
        this.msgError = true
        this.missingSize = true
        await setTimeout(() => {
          this.missingSize = false
        }, 1000)
      }
    },
    async showImgCarousel() {
      this.showImgsCarousel = false
      this.showModalNow = true
      let elThis = this
      setTimeout(function() {
        elThis.showImgsCarousel = true
      }, 10)
      this.validateDotsCarousel
      /* -- | Se hace este timeout para que las imagenes se acomoden
            y no se haga una previsualización de las imagenes en columna | -- */
    },
    async validateDotsCarousel() {
      await setTimeout(() => {
        let element = document
          .getElementById('modal-product-detail-' + this.product.producto_sku)
          ?.getElementsByClassName('owl-dot')
        element?.forEach(e => {
          e.ariaLabel = 'Dot carousel'
          e.style.width =
            100 /
              (this.product.imagenes
                ? this.product.imagenes
                : this.product.referencia.imagenes) -
            1 +
            '%'
        })
      }, 100)
    },
  },
}
</script>
