var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-strategy-bag"},[_c('router-link',{staticClass:"title-product-strategy",attrs:{"to":{
        name: 'ProductDetail',
        params: { product: _vm.productStrategy.url.replace('product-', '') },
      }}},[_c('img',{staticClass:"img-strategy-bag",attrs:{"src":_vm.productStrategy.referencias[_vm.indexRef].imagenes,"alt":"producto en oferta!"}})]),_c('div',{staticClass:"content-data-product-strategy d-block d-md-none"},[_c('router-link',{staticClass:"title-product-strategy",attrs:{"to":{
          name: 'ProductDetail',
          params: { product: _vm.productStrategy.url.replace('product-', '') },
        }}},[_vm._v(_vm._s(_vm.productStrategy.titulo .toLowerCase() .replace(/^\w/, d => d.toUpperCase())))]),_c('br'),_c('span',{staticClass:"data-product-strategy"},[_vm._v(" SKU: "+_vm._s(_vm.productStrategy.producto_sku)+" "),_c('br'),_vm._v(" Color: "+_vm._s(_vm.productStrategy.referencias[_vm.indexRef].color_nombre)+" ")]),_c('div',{staticClass:"price-product-strategy"},[_c('p',[_vm._v(" "+_vm._s(_vm.productStrategy.precio == 0 ? 'Gratis' : '$' + new Intl.NumberFormat('de-DE').format(_vm.productStrategy.precio))+" ")]),_c('del',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.productStrategy.precio_antes, )))])])],1),_c('div',{staticClass:"strategy-bag"},[_c('div',{staticClass:"content-data-product-strategy d-none d-md-block"},[_c('router-link',{staticClass:"title-product-strategy",attrs:{"to":{
            name: 'ProductDetail',
            params: { product: _vm.productStrategy.url.replace('product-', '') },
          }}},[_vm._v(_vm._s(_vm.productStrategy.titulo .toLowerCase() .replace(/^\w/, d => d.toUpperCase())))]),_c('br'),_c('span',{staticClass:"data-product-strategy"},[_vm._v(" SKU: "+_vm._s(_vm.productStrategy.producto_sku)+" "),_c('br'),_vm._v(" Color: "+_vm._s(_vm.productStrategy.referencias[_vm.indexRef].color_nombre)+" "),_c('br')]),_c('div',{staticClass:"price-product-strategy"},[_c('p',[_vm._v(" "+_vm._s(_vm.productStrategy.precio == 0 ? 'Gratis' : '$' + new Intl.NumberFormat('de-DE').format( _vm.productStrategy.precio, ))+" ")]),_c('del',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.productStrategy.precio_antes, )))])])],1),_c('div',{staticClass:"content-color-size-agg-strategy"},[_c('div',{staticClass:"container-color-strategy"},[_c('p',{staticClass:"title-color-size-strategy",class:_vm.productStrategy.referencias[_vm.indexRef].tallas.length == 1 &&
              _vm.sizeSelected == 'UN'
                ? 'mb-4'
                : ''},[_vm._v(" Talla: "),_c('strong',[_vm._v(_vm._s(_vm.productStrategy.referencias[_vm.indexRef].tallas.length == 1 && _vm.sizeSelected == 'UN' ? _vm.productStrategy.referencias[_vm.indexRef].tallas[0].name : ''))])]),(
              !(
                _vm.productStrategy.referencias[_vm.indexRef].tallas.length == 1 &&
                _vm.sizeSelected == 'UN'
              )
            )?_c('div',{staticClass:"d-flex"},_vm._l((_vm.productStrategy.referencias[_vm.indexRef]
                .tallas),function(size,index){return _c('div',{key:index + 'size',staticClass:"d-flex pe-3",on:{"click":function($event){_vm.sizeSelected = size.id}}},[_c('span',{staticClass:"sizes-product",class:_vm.sizeSelected == size.id ? 'size-selected' : '',style:(_vm.missingSize
                    ? `animation-name: missingSize; animation-duration: .5s;`
                    : '')},[_vm._v(_vm._s(size.name))])])}),0):_vm._e()]),_c('div',{staticClass:"container-sizes-strategy"},[_c('p',{staticClass:"title-color-size-strategy"},[_vm._v("Color:")]),_c('div',{staticClass:"d-flex"},_vm._l((_vm.productStrategy.referencias),function(color,index){return _c('div',{key:index + 'colores',staticClass:"d-flex pe-2",on:{"click":function($event){_vm.colorSelected = color.color_id
                _vm.indexRef = index}}},[_c('div',{staticClass:"contenedor-colors-product-detail",class:_vm.colorSelected == color.color_id
                    ? 'color-selected-product-detail'
                    : ''},[_c('div',{staticClass:"colors-product-detail",style:('bacground-color:' +
                      color.color_hx +
                      ';background-image: url(' +
                      color.color_textura +
                      ')')})])])}),0)]),_c('button',{staticClass:"btn-agg-strategy edit-data-perfil-button",on:{"click":function($event){return _vm.agregarProducto()}}},[(!_vm.loading)?_c('div',[_c('img',{attrs:{"src":require("@/assets/icons/shopping-bag.svg"),"alt":""}}),_vm._v(" Agregar a la bolsa ")]):_c('div',{staticClass:"d-flex justify-content-center"},[_vm._v(" Cargando... "),_c('div',{staticClass:"loader"})])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }