<template>
  <div class="container-card-carousel">
    <div class="carousel-container">
      <div
        v-if="showModal"
        class="show-modal-carousel w-100"
        @click="$bvModal.show('modal-product-detail-' + product.producto_sku)"
      >
        <img
          :src="
            product.imagenes
              ? product.imagenes[0]
              : product.referencia.imagenes[0]
          "
          :alt="product.producto_titulo"
          class="img-product-carousel"
        />
        <img
          v-if="product.imagenes && product.imagenes.length > 1"
          :src="product.imagenes[1]"
          :alt="product.producto_titulo"
          class="img-product-carousel-hover"
        />
      </div>
      <router-link
        v-else
        class="w-100"
        :to="{
          name: 'ProductDetail',
          params: {
            product: product.url.replace('product-', ''),
            relatedProductGA4: $route.name == 'ProductDetail',
            referenciaGA4: location,
          },
          query: { color: product.color },
        }"
      >
        <img
          :src="
            product.imagenes
              ? product.imagenes[0]
              : product.referencia.imagenes[0]
          "
          :alt="product.producto_titulo"
          class="img-product-carousel"
        />
        <img
          v-if="product.imagenes && product.imagenes.length > 1"
          :src="product.imagenes[1]"
          :alt="product.producto_titulo"
          class="img-product-carousel-hover"
        />
        <div
          v-if="!(product.tallas.length == 1 && product.tallas[0].id == 'UN')"
          class="box-sizes-carousel mx-auto"
        >
          <div class="d-flex flex-wrap justify-content-center">
            <router-link
              v-for="(item, index) in product.tallas"
              :key="index"
              class="size"
              :class="index == product.tallas.length - 1 ? 'me-0' : ''"
              :to="{
                name: 'ProductDetail',
                params: {
                  product: product.url.replace('product-', ''),
                  talla: item.id,
                },
                query: { color: product.color },
              }"
            >
              {{ item.name }}
            </router-link>
          </div>
        </div>
      </router-link>
      <div
        style="display: flex; flex-wrap: wrap; position: absolute; top: 0; left: 0; cursor:"
      >
        <!-- <div class="tags-product-detail me-3">
                    <div class="tag-dia-sin-iva my-0">
                        <p class="mb-0"><b>SIN IVA</b></p>
                    </div>
                </div> -->
        <span
          v-for="(tag, index) in product.tags"
          :key="index"
          :style="
            `background-color: ${tag.color_fondo}; color: ${tag.color_texto}`
          "
          class="store-product-tags"
          >{{ tag.tag }}</span
        >
      </div>
      <div
        v-if="showModal"
        style="position: absolute; top: 0; right: 1rem; padding: .2rem; background-color: white; cursor: pointer;"
        @click="$bvModal.show('modal-product-detail-' + product.producto_sku)"
      >
        <img src="@/assets/icons/icon-lupa.svg" alt="" />
      </div>
      <button-fav
        v-else
        :data-product="product"
        class="carousel-fav"
        aria-label="Add favorite"
      />
      <div class="d-flex px-4 py-4 justify-content-evenly w-100">
        <div v-if="showModal" class="d-flex flex-wrap w-100">
          <div class="col-8 text-title" :class="opt2 ? 'col-12' : ''">
            <p
              style="cursor: pointer; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; min-height: 38px;"
              @click="
                $bvModal.show('modal-product-detail-' + product.producto_sku)
              "
            >
              {{
                product.producto_titulo
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}
            </p>
          </div>
          <div
            v-if="product.dia_siniva == 1"
            class="col-4 text-price"
            :class="
              opt2
                ? 'col-12 text-start pt-2 d-flex flex-wrap align-items-center justify-content-between'
                : ''
            "
          >
            <del
              >$
              {{
                new Intl.NumberFormat('de-DE').format(
                  product.producto_precio
                    ? product.producto_precio
                    : product.precio,
                )
              }}</del
            >
            <p
              class="text-end mt-1"
              style="line-height: .8; font-size: 1.1rem;"
            >
              <b
                >${{
                  new Intl.NumberFormat('de-DE').format(
                    product.producto_precio_siniva,
                  )
                }}</b
              >
              <br />
              <span style="font-size: 11px">*Precio sin IVA</span>
            </p>
          </div>
          <div
            v-else
            class="col-4 text-price"
            :class="
              opt2
                ? 'col-12 text-start pt-2 d-flex flex-wrap align-items-center justify-content-between'
                : ''
            "
          >
            <p>
              $
              {{
                new Intl.NumberFormat('de-DE').format(
                  product.producto_precio
                    ? product.producto_precio
                    : product.precio,
                )
              }}
            </p>
            <del
              v-show="
                product.producto_precio_antes
                  ? product.producto_precio_antes > 0
                  : product.precio_antes > 0
              "
              >$
              {{
                new Intl.NumberFormat('de-DE').format(
                  product.producto_precio_antes
                    ? product.producto_precio_antes
                    : product.precio_antes,
                )
              }}</del
            >
          </div>
        </div>
        <router-link
          v-else
          class="d-flex w-100"
          :to="{
            name: 'ProductDetail',
            params: { product: product.url.replace('product-', '') },
            query: { color: product.color },
          }"
        >
          <div class="col-8 text-title">
            <p
              style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; min-height: 38px;"
            >
              {{
                product.producto_titulo
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}
            </p>
          </div>
          <div v-if="product.dia_siniva == 1" class="col-4 text-price">
            <del
              >$
              {{
                new Intl.NumberFormat('de-DE').format(product.producto_precio)
              }}</del
            >
            <p
              class="text-end mt-1"
              style="line-height: .8; font-size: 1.1rem;"
            >
              <b
                >${{
                  new Intl.NumberFormat('de-DE').format(
                    product.producto_precio_siniva,
                  )
                }}</b
              >
              <br />
              <span style="font-size: 11px">*Precio sin IVA</span>
            </p>
          </div>
          <div v-else class="col-4 text-price">
            <p>
              $
              {{
                new Intl.NumberFormat('de-DE').format(product.producto_precio)
              }}
            </p>
            <del v-show="product.producto_precio_antes > 0"
              >$
              {{
                new Intl.NumberFormat('de-DE').format(
                  product.producto_precio_antes,
                )
              }}</del
            >
          </div>
        </router-link>
      </div>
    </div>
    <modal-product-detail :product="product" />
  </div>
</template>
<script>
import ButtonFav from '@/components/Templates/ButtonFav.vue'
import ModalProductDetail from '@/components/Templates/ModalProductDetail.vue'

export default {
  components: { ButtonFav, ModalProductDetail },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {},
    },
    showModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    opt2: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      location: window.location.pathname,
    }
  },
  watch: {
    $router(to, from) {
      if (to.path != from.path) {
        location.reload()
      }
    },
  },
}
</script>
