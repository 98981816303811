<template>
  <div v-if="dataCart" class="info-bag">
    <h3 style="font-size: 1.5rem;">Resumen de compra</h3>
    <div v-if="!dataCart.cupon.codigo" class="input-group">
      <div class="input-cupon">
        <input
          v-model="codCupon"
          type="text"
          class="input-cupon w-100"
          style="border: none"
          placeholder="Cupón de descuento"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
        />
        <span v-if="codCupon" class="newlatter-error-input">
          Cupón de descuento
        </span>
      </div>
      <span
        id="basic-addon2"
        class="btn-cupon"
        @click="!loading ? validateCupon() : ''"
      >
        <div v-if="!loading">Validar</div>
        <div v-else class="d-flex justify-content-center">
          <div class="loader" />
        </div>
      </span>
      <p v-if="msgErrorCupon" class="msgError fs-6 mx-auto mt-2">
        {{ msgErrorCupon }}
      </p>
    </div>
    <div class="info-bag-prices">
      <div class="data-bag-price">
        <p class="">Subtotal:</p>
        <p class="">
          ${{
            new Intl.NumberFormat('de-DE').format(dataCart.carrito_subtotal)
          }}
        </p>
      </div>
      <div class="data-bag-price">
        <p class="">Envío:</p>
        <p
          v-if="dataCart.infoEstrategiaVentas"
          :style="
            'color: #654062; font-family: PT Sans Bold; text-transform: capitalize;'
          "
        >
          {{ dataCart.infoEstrategiaVentas.tipo_estrategia }}
          <img
            v-if="true"
            id="cupon-popover"
            v-b-popover.hover.left="
              dataCart.infoEstrategiaVentas.tooltip_descripcion
            "
            src="@/assets/icons/icon-info.svg"
          />
        </p>
        <p
          v-else
          :style="
            dataCart.envio.dias_entrega
              ? ''
              : 'cursor: pointer; color: #654062; font-family: PT Sans Bold;'
          "
          @click="dataCart.carrito_envio ? '' : (showShipping = !showShipping)"
        >
          {{
            dataCart.envio.dias_entrega
              ? dataCart.envio.total == 0 &&
                dataCart.cupon.descuento_envio != null
                ? 'Envio gratis'
                : '$' +
                  new Intl.NumberFormat('de-DE').format(dataCart.envio.total)
              : showShipping
              ? 'Ocultar'
              : 'Por calcular'
          }}
        </p>
      </div>
      <calc-shipping v-if="showShipping" class="calc-shipping-transition" />
      <div
        v-if="dataCart.carrito_descuento > 0"
        :key="dataCart.carrito_descuento"
        class="data-bag-price"
      >
        <p class="">Descuentos:</p>
        <p class="">
          ${{
            new Intl.NumberFormat('de-DE').format(dataCart.carrito_descuento)
          }}
        </p>
      </div>
      <div v-if="dataCart.cupon.codigo">
        <div class="content-cupon-box">
          <div class="box-delete-cupon">
            <img
              v-if="!deletingCupon"
              class="me-2 p-0"
              src="@/assets/icons/delete-gray-icon.svg"
              alt="boton cerrar"
              @click="deleteCuponCart()"
            />
            <div v-else class="mt-1 ms-0 me-1 loader loader-delete" />
            <span>cupón: </span>
          </div>
          <span>{{ dataCart.cupon.codigo }}</span>
          <img
            v-if="dataCart.cupon.tooltip_titulo"
            id="cupon-popover"
            v-b-popover.hover.left="dataCart.cupon.tooltip_descripcion"
            src="@/assets/icons/icon-info.svg"
          />
        </div>
      </div>
      <div
        v-if="dataCart.carrito_bono_codigo && dataCart.carrito_bono_valor"
        class="mb-3"
      >
        <div class="content-cupon-box">
          <div class="box-delete-cupon">
            <img
              class="me-2 p-0"
              src="@/assets/icons/delete-gray-icon.svg"
              alt="boton cerrar"
              @click="removeBono()"
            />
            <span class="mb-1"
              ><b>Bono regalo:</b> {{ dataCart.carrito_bono_codigo }}</span
            >
          </div>
          <span class="mb-1"
            >-${{
              new Intl.NumberFormat('de-DE').format(
                dataCart.carrito_bono_valor_aplicado,
              )
            }}</span
          >
        </div>
      </div>
      <div class="data-bag-price">
        <p class="bold">Total:</p>
        <p class="bold">
          ${{ new Intl.NumberFormat('de-DE').format(dataCart.carrito_total) }}
        </p>
      </div>
    </div>
    <button
      :disabled="dataCart.carrito_subtotal == 0"
      :class="dataCart.carrito_subtotal == 0 ? 'btn-primary-disabled' : ''"
      class="btn-primary btn-finalice-bag"
      type=""
      @click="$router.push({ name: 'Payment' })"
    >
      Finalizar Compra
    </button>
    <div class="my-4">
      <h3 class="mb-3" style="font-size: 1.5rem;">
        Nuestros medios de pago
      </h3>
      <div class="content-medios-pago">
        <img
          src="@/assets/icons/mediosPago/visa.svg"
          alt="icono visa"
          style="max-width: 40px"
        />
        <img
          src="@/assets/icons/mediosPago/mastercard.svg"
          alt="icono mastercard"
          style="max-width: 32px"
        />
        <img
          src="@/assets/icons/mediosPago/diners.svg"
          alt="icono diners"
          style="max-width: 66px"
        />
        <img
          src="@/assets/icons/mediosPago/pse.svg"
          alt="icono pse"
          style="max-width: 33px"
        />
        <img
          src="@/assets/icons/mediosPago/amex.svg"
          alt="icono american express"
          style="max-width: 32px"
        />
        <img
          src="@/assets/icons/mediosPago/efecty.svg"
          alt="icono efecty"
          style="max-width: 58px"
        />
        <img
          src="@/assets/icons/mediosPago/nequi.svg"
          alt="icono nequi"
          style="max-width: 72px"
        />
        <img
          src="@/assets/icons/mediosPago/daviplata.svg"
          alt="icono daviplata"
          style="max-width: 38px"
        />
        <img
          src="@/assets/icons/mediosPago/rappi.svg"
          alt="icono rappi"
          style="max-width: 39px"
        />
        <img
          src="@/assets/icons/mediosPago/addi.svg"
          alt="icono addi"
          style="max-width: 47px"
        />
        <img
          src="@/assets/icons/mediosPago/quac.svg"
          alt="icono quac"
          style="max-width: 60px"
        />
        <img
          src="@/assets/icons/mediosPago/sistecredito.svg"
          alt="icono sistecredito"
          style="max-width: 82px"
        />
      </div>
    </div>
    <modal-frecuent-questions
      v-if="dataModalFrecuentQuestions"
      :data="dataModalFrecuentQuestions"
    />
    <b-modal
      id="modal-cupon"
      v-model="modalCupon"
      centered
      hide-footer
      hide-header
    >
      <div class="p-3">
        <h3>
          <b>{{ dataCart.cupon.tooltip_titulo }}</b>
        </h3>
        <p class="fs-6">{{ dataCart.cupon.tooltip_descripcion }}</p>
        <p>
          <a target="_blank" :href="dataCart.cupon.link_pagina_condiciones">
            ver más
          </a>
        </p>
        <button class="btn-primary px-5" @click="modalCupon = false">
          Entendido
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ModalFrecuentQuestions from '@/components/Bag/ModalFrecuentQuestions'
import CalcShipping from '@/components/Templates/CalcShipping.vue'

import { mapState, mapActions } from 'vuex'
export default {
  components: { ModalFrecuentQuestions, CalcShipping },
  data() {
    return {
      dataModalFrecuentQuestions: null,
      showShipping: false,
      codCupon: '',
      loading: false,
      deletingCupon: false,
      modalDiaSinIva: 0,
      modalCupon: false,
    }
  },
  computed: {
    ...mapState('cart', ['dataCart', 'msgErrorCupon']),
  },
  mounted() {},
  methods: {
    ...mapActions('cart', ['applyCupon', 'removeCupon', 'removeBono']),
    async showModalFrecuentQuestions(idPreFre) {
      let arrayOptPreFre = [
        {
          title: '¿Cómo pagar con mi cupo personal?',
          description:
            'Agrega tu producto favorito al carrito, selecciona el método de pago CUPO PERSONAL, autentícate con tu número de celular registrado y recibe tu pedido en casa. Tu compra será diferida en 10 cuotas quincenales.',
        },
        {
          title: '¿Cómo usar un cupón de descuento?',
          description:
            'En la caja de texto que se encuentra en el resumen de la compra, se encuentra una caja de texto con la descripción de (Cupón de descuento), ingresa tu código de descuento y se aplicara el respectivo cupón a tu carrito.',
        },
        {
          title: '¿Cuánto demora mi pedido?',
          description:
            'El tiempo de entrega de los productos varía dependiendo de la ciudad de destino de la compra, no obstante, esta será de 7-12 días hábiles.  Al momento de realizar el cliente el checkout y luego de seleccionar el departamento y la ciudad, se le informará el tiempo máximo de entrega. Este tiempo no incluye el tiempo de aprobación del medio de pago.',
        },
        {
          title: 'Cambios y devoluciones',
          description:
            'BAGUER S.A.S ofrece a sus clientes cambio de artículo por: <b>1.</b> Talla <b>2.</b> Color <b>3.</b> Referencia, en un plazo máximo de 30 días después de recibido/comprado el producto. Para hacer el proceso de cambio, el producto debe estar nuevo, sin uso y con todos sus empaques originales, piezas, accesorios, manuales completos y etiquetas adheridas al mismo. Conoce más en nuestra política aquí <a style="font-size: 1rem; color: #0d6efd !important" href="https://derek.co/politica-de-cambios">https://derek.co/politica-de-cambios</a>',
        },
      ]
      this.dataModalFrecuentQuestions = arrayOptPreFre[idPreFre]
      await setTimeout(() => {
        this.$bvModal.show('modal-questions-bag')
      }, 10)
    },
    async validateCupon() {
      if (this.codCupon) {
        this.loading = true
        await this.applyCupon({ discountCode: this.codCupon }).then(() => {
          this.loading = false
          if (!this.msgErrorCupon) {
            this.codCupon = ''
          }
        })
        if (this.dataCart.cupon.tooltip_titulo) {
          this.modalCupon = true
        }
      }
    },
    async deleteCuponCart() {
      this.deletingCupon = true
      await this.removeCupon().then(() => {
        this.deletingCupon = false
      })
    },
  },
}
</script>
