var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{staticClass:"m-5",attrs:{"id":'modal-product-detail-' + _vm.product.producto_sku,"size":"lg","centered":"","hide-header":"","hide-footer":""},on:{"show":_vm.showImgCarousel}},[_c('div',{staticClass:"d-flex flex-wrap p-0"},[_c('div',{staticClass:"col-12 col-md-6 position-relative p-3 pt-4 p-md-0"},[(_vm.showImgsCarousel)?_c('carousel',{key:_vm.innerWidth,staticClass:"col-12 mx-0 carousel-modal-product-detail",attrs:{"dots":false,"lazy-load":true,"loop":true,"nav":false,"responsive":{ 0: { items: 1, nav: false, stagePadding: 0 } }}},[_c('template',{slot:"prev"},[_c('span',{staticClass:"prev-button"},[_c('img',{attrs:{"src":require("@/assets/icons/left-carousel.svg")}})])]),_c('template',{slot:"next"},[_c('span',{staticClass:"next-button"},[_c('img',{attrs:{"src":require("@/assets/icons/right-carousel.svg")}})])]),_vm._l((_vm.product.imagenes
              ? _vm.product.imagenes
              : _vm.product.referencia.imagenes),function(img,index){return _c('div',{key:index,staticClass:"imgs-modal-carousel-product-detail",attrs:{"to":{}}},[_c('img',{attrs:{"src":img,"alt":_vm.product.producto_titulo}})])})],2):_vm._e(),_c('div',{staticStyle:{"position":"absolute","top":"0","left":"0","z-index":"1"}},_vm._l((_vm.product.tags),function(tag,index){return _c('span',{key:index,staticClass:"store-product-tags",style:(`z-index: 2; background-color: ${tag.color_fondo}; color: ${tag.color_texto}`)},[_vm._v(_vm._s(tag.tag))])}),0)],1),_c('div',{staticClass:"col-12 col-md-6 modal-data-product-detail"},[_c('div',{staticClass:"container-modal-data-product-detail pt-0 pt-md-3 text-start mb-auto"},[_c('h2',{staticClass:"title-product-detail-modal"},[_vm._v(" "+_vm._s(_vm.product.producto_titulo)+" ")]),_c('span',{staticClass:"sku-product-detail-modal"},[_vm._v("SKU: "+_vm._s(_vm.product.producto_sku))]),(_vm.product.dia_siniva == 1)?_c('div',{staticClass:"price-product-detail-modal"},[_c('del',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.product.producto_precio ? _vm.product.producto_precio : _vm.product.precio, )))]),_c('p',{staticClass:"ms-3"},[_c('b',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.product.producto_precio_siniva, )))])])]):_c('div',{staticClass:"price-product-detail-modal"},[_c('p',[_c('b',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.product.producto_precio ? _vm.product.producto_precio : _vm.product.precio, )))])]),(
                (_vm.product.precio_antes && _vm.product.precio_antes > 0) ||
                  (_vm.product.producto_precio_antes &&
                    _vm.product.producto_precio_antes > 0)
              )?_c('del',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.product.precio ? _vm.product.precio : _vm.product.producto_precio_antes, )))]):_vm._e()]),_c('div',{staticClass:"box-sizes-product-detail"},[_c('span',{domProps:{"innerHTML":_vm._s(
                (_vm.product.tallas &&
                  _vm.product.tallas.length == 1 &&
                  _vm.product.tallas[0].id == 'UN') ||
                (_vm.product.referencia &&
                  _vm.product.referencia.tallas.length == 1 &&
                  _vm.product.referencia.tallas[0].id == 'UN')
                  ? 'Talla: <b>' +
                    (_vm.product.tallas
                      ? _vm.product.tallas[0].name
                      : _vm.product.referencia.tallas[0].name) +
                    '</b>'
                  : 'Selecciona tu talla'
              )}}),(
                !(
                  (_vm.product.tallas &&
                    _vm.product.tallas.length == 1 &&
                    _vm.product.tallas[0].id == 'UN') ||
                  (_vm.product.referencia &&
                    _vm.product.referencia.tallas.length == 1 &&
                    _vm.product.referencia.tallas[0].id == 'UN')
                )
              )?_c('div',{staticClass:"select-size-product"},[_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.product.tallas
                    ? _vm.product.tallas
                    : _vm.product.referencia.tallas),function(size,index){return _c('div',{key:index,staticClass:"d-flex pe-3"},[_c('span',{staticClass:"sizes-product",class:_vm.selectedSize == size.id ? 'size-selected' : '',style:(_vm.missingSize
                        ? `animation-name: missingSize; animation-duration: .5s;`
                        : ''),on:{"click":function($event){_vm.msgError = false
                      _vm.selectedSize = size.id}}},[_vm._v(_vm._s(size.name))])])}),0)]):_vm._e(),(_vm.msgError)?_c('span',{staticClass:"msgError"},[_vm._v("Selecciona una talla")]):_vm._e()])]),_c('div',{staticClass:"w-100 mt-auto px-3 pt-4"},[_c('button',{staticClass:"btn-primary btn-add-cart-product-detail",attrs:{"type":""},on:{"click":function($event){return _vm.agregarProducto()}}},[(!_vm.loading)?_c('div',[_c('img',{attrs:{"src":require("@/assets/icons/shopping-bag.svg"),"alt":""}}),_vm._v(" Agregar a la bolsa ")]):_c('div',{staticClass:"d-flex justify-content-center"},[_vm._v(" Cargando... "),_c('div',{staticClass:"loader"})])])]),_c('div',{staticClass:"w-100 px-3 mt-auto pt-2 pb-1"},[_c('button',{staticClass:"w-100 btn-summary-data-cupo",staticStyle:{"color":"black"},on:{"click":function($event){return _vm.$router.push({
                path: _vm.product.url,
                query: { color: _vm.product.color },
                params: _vm.dataga4,
              })}}},[_vm._v(" Ver detalles del producto ")])])]),_c('img',{staticClass:"btn-close-modal-product-detail",attrs:{"src":require("@/assets/icons/delete-gray-icon.svg"),"alt":"boton de cerrar"},on:{"click":function($event){_vm.$bvModal.hide('modal-product-detail-' + _vm.product.producto_sku)
          _vm.showModalAddProduct = false}}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }