<template>
  <b-modal
    id="modal-questions-bag"
    class="m-5"
    centered
    hide-header
    hide-footer
  >
    <div class="content-moda-questions-bag">
      <h4>{{ dinfoQuestionata.title }}</h4>
      <p v-html="infoQuestion.description"></p>
    </div>
    <img
      class="btn-close-add-to-cart"
      src="@/assets/icons/delete-gray-icon.svg"
      alt="boton de cerrar"
      @click="$bvModal.hide('modal-questions-bag')"
    />
  </b-modal>
</template>
<script>
export default {
  props: {
    infoQuestion: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
}
</script>
